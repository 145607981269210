import React from "react"
import { graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import Sitepage from "../../types/Sitepage"
import Image from "gatsby-plugin-sanity-image"
// import Layout from "../../components/layout"
import Layout from "../../components/layout"
import FirstTable from "./table/FirstTable"
import SecondTable from "./table/SecondTable"
// import SpellsSection from "./components/spells"

interface DataProps {
  data: {
    site: Sitepage
  }
}

const SitePage: React.FC<DataProps> = ({ data }) => {
  const template = data.site.template
  const h1ClassName =
    template === "Standard"
      ? "text-mocha text-5xl font-display font-bold text-center"
      : "text-center text-mocha text-5xl font-display font-bold"

  const imageClassName =
    template === "Standard"
      ? " sm:float-right sm:h-112 classImage"
      : "mt-5 mb-5 classImages"

  const BlockRenderer = props => {
    const { style } = props.node
    if (/^h\d/.test(style)) {
      const CustomTag = `${style}` as keyof JSX.IntrinsicElements
      const alignCenter =
        props?.node?.children[0]?.marks?.indexOf("center") >= 0
      const alignRight = props?.node?.children[0]?.marks?.indexOf("right") >= 0
      if (alignCenter || alignRight) {
        return (
          <div
            id={`${props?.node?.children[0]?.text
              ?.replace(/[^a-zA-Z ]/g, "")
              ?.replace(/\s+/g, " ")
              .trim()
              ?.replace(/ /g, "-")
              ?.toLowerCase()}`}
            style={{
              textAlign: alignCenter ? "center" : "right",
            }}
          >
            <CustomTag>{props.children}</CustomTag>
          </div>
        )
      }
      return (
        <div
          id={`${props?.node?.children[0]?.text
            ?.replace(/[^a-zA-Z ]/g, "")
            ?.replace(/\s+/g, " ")
            .trim()
            ?.replace(/ /g, "-")
            ?.toLowerCase()}`}
        >
          <CustomTag>{props.children}</CustomTag>
        </div>
      )
    }
    if (props?.children[0] === `insert ${data?.site?.table[0]?.title}`) {
      return <FirstTable tableData={data} />
    }

    for (let i = 1; i < data?.site?.table.length; i++) {
      if (props?.children[0] === `insert ${data?.site?.table[i]?.title}`) {
        return <SecondTable tableData={data?.site?.table[i]} />
      }
    }

    const alignCenter = props?.node?.children[0]?.marks?.indexOf("center") >= 0
    const alignRight = props?.node?.children[0]?.marks?.indexOf("right") >= 0

    if (alignCenter || alignRight) {
      return (
        <p style={{ textAlign: alignCenter ? "center" : "right" }}>
          {props.children}
        </p>
      )
    }
    return BlockContent.defaultSerializers.types.block(props)
  }

  const renderChildContent = (data: any) => {
    return (
      <BlockContent
        blocks={data.site._rawFlavorText}
        serializers={{ types: { block: BlockRenderer } }}
      />
    )
  }

  return (
    <Layout>
      <div className="bg-texture block borderImageFull">
        <div className="max-w-4xl mx-auto px-6 sm:px-0 bg-title">
          <div className="mt-12 titleStyle">
            <h1 className={h1ClassName}>{data.site.name}</h1>
          </div>
          <div className={imageClassName}>
            <Image
              {...data.site.image}
              alt={`${data.site.name} Avatar`}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </div>
          <div className="font-body text-fontSize body max-w-none mx-auto prose leading-relaxed font-medium">
            {renderChildContent(data)}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SitePage

export const query = graphql`
  query($name: String!) {
    site: sanityPage(name: { eq: $name }) {
      id
      name
      _rawFlavorText
      template
      image {
        ...ImageWithPreview
      }
      table {
        data {
          rows {
            cells
          }
        }
        title
      }
    }
  }
`
