// import React from "react"

// const SecondTable = props => {
//   const { tableData } = props
//   const data = tableData?.data?.rows || []
//   const headers = data[0]?.cells || []

//   const groupDataByAttribute = (data, attribute) => {
//     let groupedData = data.reduce((groups, item) => {
//       const group = item.cells[attribute]
//       if (!groups[group]) {
//         groups[group] = []
//       }
//       groups[group].push(item)
//       return groups
//     }, {})
//     return groupedData
//   }

//   const groupedData = groupDataByAttribute(data.slice(1), headers.length - 1)

//   return (
//     <div>
//       <div>
//         <table className="min-w-full divide-y divide-gray-200">
//           <thead className="bg-gray-50 bcg">
//             <tr>
//               {headers.slice(0, headers.length - 1).map((header, index) => (
//                 <th
//                   key={index}
//                   className="first-table text-center px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider wc"
//                 >
//                   {header}
//                 </th>
//               ))}
//             </tr>
//           </thead>
//           <tbody>
//             {Object.keys(groupedData).map((key, index) => (
//               <React.Fragment key={index}>
//                 <th
//                   style={{ color: "white" }}
//                   className="first-table text-center pt-1 bg-gray-50 bcg "
//                   colSpan={6}
//                 >
//                   {key}
//                 </th>
//                 {groupedData[key].map((item, itemIndex) => (
//                   <tr key={itemIndex}>
//                     {item.cells
//                       .slice(0, item.cells.length - 1)
//                       .map((cell, cellIndex) => (
//                         <td key={cellIndex} className="first-table">
//                           {cell}
//                         </td>
//                       ))}
//                   </tr>
//                 ))}
//               </React.Fragment>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   )
// }

// export default SecondTable

import React from "react"

const SecondTable = props => {
  const { tableData } = props
  const data = tableData?.data?.rows || []
  const headers = data[0]?.cells || []

  const groupDataByAttribute = (data, attribute) => {
    let groupedData = data.reduce((groups, item) => {
      const group = item.cells[attribute]
      if (!groups[group]) {
        groups[group] = []
      }
      groups[group].push(item)
      return groups
    }, {})
    return groupedData
  }

  const groupedData = groupDataByAttribute(data.slice(1), headers.length - 1)

  return (
    <div>
      <div>
        <table className="min-w-full divide-y divide-gray-200 custom-table">
          <thead className="bcg">
            <tr>
              {headers.slice(0, headers.length - 1).map((header, index) => (
                <th
                  key={index}
                  className="first-table text-center px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider wc"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.keys(groupedData).map((key, index) => (
              <React.Fragment key={index}>
                <th
                  style={{ color: "white" }}
                  className="first-table text-center pt-1 bcg"
                  colSpan={6}
                >
                  {key}
                </th>
                {groupedData[key].map((item, itemIndex) => (
                  <tr key={itemIndex} className="border">
                    {item.cells
                      .slice(0, item.cells.length - 1)
                      .map((cell, cellIndex) => (
                        <td
                          key={cellIndex}
                          className="third-table border px-6 py-4"
                        >
                          {cell}
                        </td>
                      ))}
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default SecondTable
