import React from "react"

{
  /* <style>


tr, td {
  padding: 8px;
  text-align: left;
  border-bottom: 3px solid #DDD;
  border-top: 3px solid #DDD;
  border-left: 3px solid #DDD;
  border-right: 3px solid #DDD;
}


</style> */
}

const FirstTable = (props: any) => {
  return (
    <div>
      {/* <h3>{props?.tableData?.site?.table[0]?.title}:</h3> */}
      <table className="min-w-full divide-y divide-gray-200 custom-table">
        <thead className="bcg">
          {/* {props?.tableData?.site?.table[0].data.rows[0].map(d => {
            return ( */}
          <tr>
            {props?.tableData?.site?.table[0].data.rows[0].cells.map(i => {
              return (
                i.length > 0 && (
                  <th
                    className="first-table text-center pt-3 px-6 py-3 text-left  text-gray-500 uppercase tracking-wider wc "
                    key={i}
                  >
                    {i}
                  </th>
                )
              )
            })}
          </tr>
          {/* )
          })} */}
          {/* <tr
            style={{ backgroundColor: "grey", color: "white" }}
            className="ml-3"
          >
            <th>With both hands free</th>
          </tr> */}
        </thead>
        <tbody>
          {props?.tableData?.site?.table[0].data.rows.slice(1).map(d => {
            return (
              <>
                <tr>
                  {d.cells.map(i => {
                    return (
                      <td className="first-table" key={i}>
                        {i}
                      </td>
                    )
                  })}
                </tr>
              </>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default FirstTable
